// index.tsx
import { GetStaticPropsContext } from 'next';
import dynamic from 'next/dynamic';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React from 'react';

// Importations statiques pour les composants critiques
import Page from '@components/Layout/Page';
import Header from '@components/Sections/Header';
import Hero from '@components/Sections/Hero';

// Importations dynamiques pour les autres sections
const About = dynamic(() => import('@components/Sections/About'), { ssr: false });
const Contact = dynamic(() => import('@components/Sections/Contact'), { ssr: false });
const Footer = dynamic(() => import('@components/Sections/Footer'), { ssr: false });
const Portfolio = dynamic(() => import('@components/Sections/Portfolio'), { ssr: false });
const Resume = dynamic(() => import('@components/Sections/Resume'), { ssr: false });
const Testimonials = dynamic(() => import('@components/Sections/Testimonials'), { ssr: false });

const Home = () => {
  const { t } = useTranslation('common');

  return (
    <Page description={t('homePageMeta.description')} title={t('homePageMeta.title')}>
      <Header />
      <Hero />
      <About />
      <Resume />
      <Portfolio />
      <Testimonials />
      <Contact />
      <Footer />
    </Page>
  );
};

export async function getStaticProps(context: GetStaticPropsContext) {
  const locale = context.locale || 'fr';

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])), // Chargez uniquement les namespaces nécessaires.
    },
  };
}

export default Home;
