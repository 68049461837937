import {ArrowDownTrayIcon} from '@heroicons/react/24/outline';

import GithubIcon from '../components/Icon/GithubIcon';
import InstagramIcon from '../components/Icon/InstagramIcon';
import LinkedInIcon from '../components/Icon/LinkedInIcon';
import RootMeIcon from '../components/Icon/RootMe';
import TwitterIcon from '../components/Icon/TwitterIcon';
import BackgroundImage from '../images/header-background.webp';
import profilepic from '../images/profilepic.webp';
import testimonialImage from '../images/testimonial.webp';
import {About, ContactSection, ContactType, Hero, Social, TestimonialSection} from './dataDef';
/**
 * Page meta data
 */

/**
 * Section definition
 */
export const SectionId = {
  Hero: 'hero',
  About: 'about',
  Contact: 'contact',
  Portfolio: 'portfolio',
  Resume: 'resume',
  Skills: 'skills',
  Stats: 'stats',
  Testimonials: 'testimonials',
} as const;

export type SectionId = (typeof SectionId)[keyof typeof SectionId];

/**
 * Hero section
 */
export const heroData: Hero = {
  imageSrc: BackgroundImage,
  actions: [
    {
      id: 'resume',
      primary: true,
      Icon: ArrowDownTrayIcon,
    },
    {
      id: 'contact',
      primary: false,
    },
  ],
};

export const aboutData: About = {
  profileImageSrc: profilepic,
};

export const testimonial: TestimonialSection = {
  imageSrc: testimonialImage,
  testimonials: [
    {
      name: '',
      text: '',
      image: 'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/169.jpg',
    },
    {
      name: '',
      text: '',
      image: 'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/14.jpg',
    },
    {
      name: '',
      text: '',
      image: 'https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/69.jpg',
    },
  ],
};

/**
 * Contact section
 */

export const contact: ContactSection = {
  items: [
    {
      type: ContactType.Email,
      text: 'theo.dadon@lacatholille.fr',
      href: 'mailto:theo.dadon@lacatholille.fr',
    },
    {
      type: ContactType.Location,
      text: 'Lille, France',
      href: 'https://www.google.ca/maps/place/Victoria,+BC/@48.4262362,-123.376775,14z',
    },
    {
      type: ContactType.Instagram,
      text: '@theo.td',
      href: 'https://www.instagram.com/theo.td/',
    },
    {
      type: ContactType.Github,
      text: 'theodadon',
      href: 'https://www.github.com/theodadon',
    },
  ],
};

/**
 * Social items
 */
export const socialLinks: Social[] = [
  {label: 'Github', Icon: GithubIcon, href: 'https://www.github.com/theodadon'},
  {label: 'RootMe', Icon: RootMeIcon, href: 'https://www.root-me.org/SainteTongue-838634'},
  {label: 'LinkedIn', Icon: LinkedInIcon, href: 'https://www.linkedin.com/in/theodadon/'},
  {label: 'Instagram', Icon: InstagramIcon, href: 'https://www.instagram.com/theo.td/'},
  {label: 'Twitter', Icon: TwitterIcon, href: 'https://twitter.com/ricklecornichon'},
];
