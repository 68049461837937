// src/components/Layout/Page.tsx

import { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

import { HomepageMeta } from '../../data/dataDef';

const Page: NextPage<PropsWithChildren<HomepageMeta>> = ({ children, title, description }) => {
  const router = useRouter();
  const canonicalUrl = `https://theodadon.fr${router.asPath}`;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} />

        {/* Lien canonique */}
        <link rel="canonical" href={canonicalUrl} />

        {/* Balises Open Graph */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="website" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
      </Head>
      {children}
    </>
  );
};

export default Page;
